import * as types from '../actions/types';

const INITIAL_STATE = {
  loggedInUser: {
    roles: [],
  },
};

export default function users(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: payload,
      };
    case types.CLEAR_LOGGED_IN_USER:
      return {
        loggedInUser: {
          roles: [],
        },
      };
    default:
      return state;
  }
}
