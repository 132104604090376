import firebase from "firebase/app";
import "firebase/storage";
import "firebase/functions";
import { ACTIVE, DELETED, NEW_STUDENT, OLD_STUDENT } from "config/constants";
import { db, functions } from "utils/store";
import * as types from "./types/students";
import _ from "lodash";
import swal from "@sweetalert/with-react";
import { setQueryConditions } from "utils/helpers";
import { COLLECTION_STUDENTS } from "constants/firestore";

export const addStudent = (data, successCallback, errorCallback) => async (dispatch, getState) => {
    dispatch(types.addStudentRequest());

    const { root } = getState();
    const { filters, users } = root || {};
    const { loggedInUser } = users || {};
    const { schoolYear, school } = filters || {};
    const { parents, photo } = data;
    let { enrollmentInfo, studentInfo } = data;

    const commonInfo = {
        schoolId: school.id,
        schoolYearId: schoolYear.id,
        status: "ACTIVE",
    };

    enrollmentInfo["createdBy"] = {
        id: loggedInUser.id,
        name: loggedInUser.displayName,
    };

    if (enrollmentInfo.type === OLD_STUDENT) {
        commonInfo["dateUpdated"] = new Date();
        commonInfo["updatedBy"] = {
            id: loggedInUser.id,
            name: loggedInUser.displayName,
        };
    } else {
        commonInfo["dateCreated"] = new Date();
        commonInfo["createdBy"] = {
            id: loggedInUser.id,
            name: loggedInUser.displayName,
        };
    }
    try {
        await db
            .collection("enrollments")
            .where("schoolYearId", "==", schoolYear.id)
            .where("id", "==", studentInfo.id)
            .get()
            .then(doc => {
                if (!doc.empty) {
                    throw { msg: "This student was already enrolled in the current school year" };
                }
            });

        await firebase
            .storage()
            .ref()
            .child(`images/students/${studentInfo.id}`)
            .put(photo)
            .then(snapshot => {
                studentInfo["photo"] = snapshot.metadata.fullPath;
            })
            .catch(error => {
                throw error;
            });

        const batch = db.batch();
        const studentRef = db.collection("students").doc(studentInfo.id);
        const enrollmentRef = db.collection("enrollments").doc();
        let studentParents = {};

        Object.keys(parents).map(id => {
            const parentRef = db.collection("parents").doc(id);
            let parentStudents = {
                ...parents[id],
                fullName: `${parents[id].firstName} ${parents[id].lastName}`,
                students: {
                    [studentInfo.id]: {
                        id: studentInfo.id,
                        fullName: studentInfo.fullName,
                    },
                },
            };

            batch.set(parentRef, { ...commonInfo, ...parentStudents }, { merge: true });
            studentParents[id] = { id, fullName: parents[id].fullName };
        });
        studentInfo = { ...studentInfo, parents: { ...studentParents } };

        batch.set(studentRef, { ...commonInfo, ...studentInfo }, { merge: true });
        batch.set(
            enrollmentRef,
            { ...commonInfo, ...enrollmentInfo, ...studentInfo },
            { merge: true }
        );

        batch
            .commit()
            .then(() => {
                const newStudentData = {
                    item: {
                        [studentInfo.id]: {
                            ...commonInfo,
                            ...enrollmentInfo,
                            ...studentInfo,
                        },
                    },
                    id: studentInfo.id,
                };
                if (studentInfo.type === NEW_STUDENT) {
                    const addStudentCount = functions.httpsCallable("addStudentCount");
                    addStudentCount({ ...studentInfo });
                }
                dispatch(types.addStudent(newStudentData));
                successCallback && successCallback({ msg: "Student data saved successfully" });
            })
            .catch(error => {
                throw error;
            });
    } catch (error) {
        dispatch(types.addStudentFailure());
        errorCallback && errorCallback(error);
    }
};

export const fetchAllStudents = (school, branch) => dispatch => {
    dispatch(types.clearStudents());
    dispatch(types.fetchingStudents());

    db.collection(COLLECTION_STUDENTS)
        .where("schoolId", "==", school)
        .where("branch", "==", branch)
        .where("status", "==", ACTIVE)
        .get()
        .then(snapshot => {
            let ids = [];
            let items = {};

            snapshot.docs.forEach(doc => {
                ids.push(doc.id);
                items[doc.id] = { ...doc.data(), docId: doc.id };
            });

            dispatch(types.doneFetchingStudents());
            dispatch(types.addingStudentItem({ ids, items }));
        })
        .catch(error => {
            console.log(error);
            dispatch(types.fetchingStudentsFailed());
        });
};

export const fetchStudents = () => dispatch => {
    dispatch(types.clearStudents());
    dispatch(types.fetchingStudents());
    let isFetching = true;
    return db
        .collection("students")
        .where("status", "==", ACTIVE)
        .orderBy("dateCreated", "desc")
        .limit(50)
        .onSnapshot(
            querySnapshot => {
                if (isFetching) {
                    isFetching = false;
                    dispatch(types.doneFetchingStudents());
                }

                querySnapshot.docChanges().forEach(change => {
                    const docId = change.doc.id;
                    const item = {
                        ids: [docId],
                        items: {
                            [change.doc.id]: change.doc.data(),
                        },
                    };

                    if (change.type === "added" || change.type === "modified") {
                        dispatch(types.addingStudentItem(item));
                    }
                    if (change.type === "removed") {
                        dispatch(
                            types.removingStudentItem({
                                id: docId,
                            })
                        );
                    }
                });
            },
            error => {
                console.error(`[Error] ${error.name}: ${error.message}`);
                dispatch(types.fetchingStudentsFailed());
            }
        );
};

export const setStudentItem = item => dispatch => {
    dispatch(
        types.setStudentItem({
            item,
        })
    );
};

export const fetchStudentCount = (schoolId, schoolYearId) => (dispatch, getState) => {
    const { root } = getState();
    const { filters } = root || {};
    const { school, schoolYear } = filters || {};
    const subscriber = db
        .collection("counters")
        .doc(`--STUDENTS-${school?.id || schoolId}-${schoolYear?.id || schoolYearId}--`)
        .onSnapshot(doc => {
            dispatch(types.fetchStudentCount(doc.data(), subscriber));
        });
};

export const fetchStudentItem = id => dispatch => {
    dispatch(types.fetchingStudentItem());
    return db
        .collection("students")
        .doc(id)
        .onSnapshot(change => {
            dispatch(types.fetchStudentItemSuccess(change.data()));
        });
};

export const editStudentAction = (id, data, successCallback) => (dispatch, getState) => {
    dispatch(types.editStudentItemRequest());
    const { root } = getState();
    const { users } = root || {};
    const { loggedInUser } = users || {};

    db.collection("students")
        .doc(id)
        .update({
            ...data,
            updatedBy: {
                id: loggedInUser.id,
                name: loggedInUser.displayName,
            },
            dateUpdated: firebase.firestore.Timestamp.now(),
        })
        .then(ref => {
            dispatch(types.editStudentItemSuccess());
            successCallback && successCallback(ref);
        })
        .catch(error => {
            dispatch(types.editStudentItemFailed());
            swal("Error!", JSON.stringify(error), "error");
        });
};

export const deleteStudent = (id, successCallback, errorCallback) => dispatch => {
    dispatch(types.deleteStudentItemRequest());

    db.collection("students")
        .doc(id)
        .update({
            status: DELETED,
        })
        .then(response => {
            dispatch(types.deleteStudentItemSuccess());
            // eslint-disable-next-line no-unused-expressions
            successCallback && successCallback(response);
        })
        .catch(error => {
            console.error(`[Error] ${error.name}: ${error.message}`);
            dispatch(types.deleteStudentItemFailed());
            // eslint-disable-next-line no-unused-expressions
            errorCallback && errorCallback(error);
        });
};

export const fetchEnrollmentsAction = (queryFilters = {}) => async (dispatch, getState) => {
    try {
        const { root } = getState();
        const { filters } = root || {};
        const { schoolYear, school } = filters || {};
        dispatch(types.clearStudents());
        dispatch(types.fetchingStudents());

        let query = db.collection("enrollments");
        query = setQueryConditions(query, queryFilters);

        const snapshot = await query
            .where("schoolId", "==", school.id)
            .where("schoolYearId", "==", schoolYear.id)
            .where("status", "==", ACTIVE)
            .get();

        snapshot.docs.forEach(doc => {
            const item = {
                ids: [doc.id],
                items: {
                    [doc.id]: { ...doc.data(), docId: doc.id },
                },
            };
            dispatch(types.addingStudentItem(item));
        });
        dispatch(types.doneFetchingStudents());
    } catch (error) {
        console.error(`[Error] ${error.name}: ${error.message}`);
        swal("Error!", JSON.stringify(error), "error");
        dispatch(types.fetchingStudentsFailed());
    }
};

export const editEnrollmentBatchAction = (data, successCallback) => (dispatch, getState) => {
    dispatch(types.editStudentItemRequest());
    const { root } = getState();
    const { users } = root || {};
    const { loggedInUser } = users || {};
    const batch = db.batch();

    data.forEach(item => {
        const ref = db.collection("enrollments").doc(item.id);
        const { id, name } = item.section;
        batch.set(
            ref,
            {
                section: {
                    id,
                    name,
                },
                updatedBy: {
                    id: loggedInUser.id,
                    name: loggedInUser.displayName,
                },
                dateUpdated: firebase.firestore.Timestamp.now(),
            },
            { merge: true }
        );
    });

    batch
        .commit()
        .then(ref => {
            dispatch(types.editStudentItemSuccess());
            successCallback && successCallback(ref);
        })
        .catch(error => {
            console.error(`[Error] ${error.name}: ${error.message}`);
            dispatch(types.editStudentItemFailed());
            swal("Error!", JSON.stringify(error), "error");
        });
};

export const clearStudentsAction = () => dispatch => {
    dispatch(types.clearStudents());
};

export const unsubscribeStudentCount = () => dispatch => {
    dispatch(types.unsubscribeStudentCount());
};
