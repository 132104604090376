import React, { Component } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/browser";

import "assets/css/material-dashboard-react.css";
import "./assets/plugins/bootstrap/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/plugins/select2/css/select2.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./index.scss";
import { theme } from "assets/jss/theme";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import configureStore from "./utils/store";

export const { store, persistor } = configureStore({});

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
});

class Root extends Component {
    render() {
        // persistor.purge();
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
