// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";

import {
    COLLECTION_ACTIVITIES,
    COLLECTION_STUDENTS,
    COLLECTION_STUDENT_SUBJECT_QUARTERLY_GRADES,
    COLLECTION_QUARTERLY_GRADES_SUMMARY,
    COLLECTION_SUBJECT_FINAL_GRADES,
    COLLECTION_GENERAL_AVERAGES,
    COLLECTION_SECTIONS,
    COLLECTION_ENROLLMENTS,
    COLLECTION_ATTENDANCE,
    COLLECTION_TEACHERS,
    COLLECTION_SCHOOLS,
    COLLECTION_SCHOOL_YEARS,
    COLLECTION_USERS,
    COLLECTION_SUBJECTS,
    COLLECTION_CLASS_SCHEDULES,
    COLLECTION_PARENTS,
    COLLECTION_PAYMENTS,
    COLLECTION_ROLES,
} from "constants/firestore";

import * as types from "./types/auth";
import * as typesV2 from "./types/base";
import { getLoggedInUser, clearUser } from "./users";
import { unsubscribeStudentCount } from "./students";
import { clearFilters } from "./filters";
import { db } from "utils/store";
import { ACTIVE } from "config/constants";
import { fbAuth } from "utils/store";

export const authListen = () => dispatch => {
    // firebase.auth().onAuthStateChanged(user => {
    fbAuth.onAuthStateChanged(user => {
        if (user) {
            dispatch(types.receiveLogin(user));
            dispatch(getLoggedInUser());
        } else {
            dispatch(types.receiveLogout());
        }
    });
};

export const getIdToken = () => dispatch => {
    // firebase
    //     .auth()
    fbAuth.currentUser
        .getIdToken(true)
        .then(idToken => {
            dispatch(types.setUserToken(idToken));
        })
        .catch(error => {
            console.error(`[Error] ${error.name}: ${error.message}`);
            dispatch(types.errorTokenRequest());
        });
};

export const loginUser = (email, password, successCallback) => dispatch => {
    dispatch(types.requestLogin());
    // firebase
    //     .auth()
    fbAuth
        .signInWithEmailAndPassword(email, password)
        .then(async user => {
            const snapshot = await db
                .collection(COLLECTION_USERS)
                .doc(user.user.uid)
                .get();

            if (snapshot.data().status !== ACTIVE) {
                console.error(`[Error] Error: INVALID_LOGIN_CREDENTIALS`);
                dispatch(types.loginError());
                return;
            }

            dispatch(types.receiveLogin(user));
            dispatch(getIdToken());

            // eslint-disable-next-line no-unused-expressions
            successCallback && successCallback(user);
        })
        .catch(error => {
            console.error(`[Error] ${error.name}: ${error.message}`);
            dispatch(types.loginError());
        });
};

export const logoutUser = () => dispatch => {
    dispatch(types.requestLogout());
    dispatch(typesV2.unsubscribe(COLLECTION_ACTIVITIES));
    dispatch(typesV2.unsubscribe(COLLECTION_STUDENTS));
    dispatch(typesV2.unsubscribe(COLLECTION_STUDENT_SUBJECT_QUARTERLY_GRADES));
    dispatch(typesV2.unsubscribe(COLLECTION_QUARTERLY_GRADES_SUMMARY));
    dispatch(typesV2.unsubscribe(COLLECTION_SUBJECT_FINAL_GRADES));
    dispatch(typesV2.unsubscribe(COLLECTION_GENERAL_AVERAGES));
    dispatch(typesV2.unsubscribe(COLLECTION_SECTIONS));
    dispatch(typesV2.unsubscribe(COLLECTION_ENROLLMENTS));
    dispatch(typesV2.unsubscribe(COLLECTION_ATTENDANCE));
    dispatch(typesV2.unsubscribe(COLLECTION_TEACHERS));
    dispatch(typesV2.unsubscribe(COLLECTION_SCHOOLS));
    dispatch(typesV2.unsubscribe(COLLECTION_SCHOOL_YEARS));
    dispatch(typesV2.unsubscribe(COLLECTION_USERS));
    dispatch(typesV2.unsubscribe(COLLECTION_SUBJECTS));
    dispatch(typesV2.unsubscribe(COLLECTION_CLASS_SCHEDULES));
    dispatch(typesV2.unsubscribe(COLLECTION_PARENTS));
    dispatch(typesV2.unsubscribe(COLLECTION_PAYMENTS));
    dispatch(typesV2.unsubscribe(COLLECTION_ROLES));
    dispatch(unsubscribeStudentCount());
    // firebase
    //     .auth()
    fbAuth
        .signOut()
        .then(() => {
            dispatch(types.receiveLogout());
            dispatch(clearUser());
            dispatch(clearFilters());
            sessionStorage.clear();
        })
        .catch(error => {
            console.error(`[Error] ${error.name}: ${error.message}`);
            dispatch(types.logoutError());
        });
};

// export const verifyAuth = () => dispatch => {
//   dispatch(types.verifyRequest());
//   firebase.auth().onAuthStateChanged(user => {
//     if (user !== null) {
//       dispatch(types.receiveLogin(user));
//     }
//     dispatch(types.verifySuccess());
//   });
// };
