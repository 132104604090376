import _ from "lodash";
import * as types from "actions/types/base";
import * as collections from "constants/firestore";

const COLLECTION_INITIAL_STATE = {
    isFetching: false,
    isListening: false,
    isSubmitting: false,
    hasError: false,
    error: "",
    ids: [],
    item: {},
    items: {},
    unsubscribe: null,
};

const collection = (state = { ...COLLECTION_INITIAL_STATE }, action) => {
    const { error, payload, type, subscription } = action || {};
    const { item, items, ids, id } = payload || {};

    switch (type) {
        case types.FETCH_REQUEST:
            return {
                ...state,
                isFetching: true,
                hasError: false,
                error: "",
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasError: false,
                error: "",
            };
        case types.FETCH_FAILED:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                error,
            };
        case types.SUBSCRIBE_REQUEST:
            return {
                ...state,
                isListening: false,
                hasError: false,
                error: "",
            };
        case types.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                isListening: true,
                hasError: false,
                error: "",
                unsubscribe: subscription,
            };
        case types.SUBSCRIBE_FAILED:
            return {
                ...state,
                isListening: false,
                hasError: true,
                error,
                unsubscribe: null,
            };

        case types.UNSUBSCRIBE:
            if (state.isListening || state.unsubscribe) {
                state.unsubscribe();
            }

            return {
                ...state,
                isListening: false,
                hasError: false,
                error: "",
                unsubscribe: null,
            };
        case types.ADD_REQUEST:
        case types.EDIT_REQUEST:
        case types.DELETE_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                hasError: false,
                error: "",
            };
        case types.ADD_SUCCESS:
        case types.EDIT_SUCCESS:
        case types.DELETE_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                hasError: false,
                error: "",
            };
        case types.ADD_FAILED:
        case types.EDIT_FAILED:
        case types.DELETE_FAILED:
            return {
                ...state,
                isSubmitting: false,
                hasError: true,
                error,
            };
        case types.ADD_ITEM:
            return {
                ...state,
                ...{
                    items: {
                        ...state.items,
                        ...items,
                    },
                    ids: Array.from(new Set([...state.ids, ...ids])),
                },
            };
        case types.EDIT_ITEM:
            return {
                ...state,
                ...{
                    items: {
                        ...state.items,
                        ...items,
                    },
                    ids: Array.from(new Set([...state.ids, ...ids])),
                },
            };
        case types.DELETE_ITEM: {
            const newState = _.cloneDeep(state);
            delete newState.items[id];
            const index = newState.ids.indexOf(id);

            return {
                ...state,
                items: newState.items,
                ids: Array.from(
                    new Set([...newState.ids.slice(0, index), ...newState.ids.slice(index + 1)])
                ),
            };
        }
        case types.SET_ITEM:
            return {
                ...state,
                item: id ? { ...state.items[id], docId: id } : item,
            };
        case types.SET_ITEMS:
            return {
                ...state,
                items,
                ids,
            };
        case types.RESET_STATE:
            return {
                isFetching: false,
                isListening: false,
                isSubmitting: false,
                hasError: false,
                error: "",
                ids: [],
                item: {},
                items: {},
                unsubscribe: null,
            };
        default:
            return state;
    }
};

// temporary
const ROOT_INITIAL_STATE = {
    [collections.COLLECTION_ACTIVITIES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_STUDENT_SUBJECT_QUARTERLY_GRADES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_QUARTERLY_GRADES_SUMMARY]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_SUBJECT_FINAL_GRADES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_GENERAL_AVERAGES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_SECTIONS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_ENROLLMENTS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_ATTENDANCE]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_TEACHERS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_SCHOOLS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_SCHOOL_YEARS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_SCHOOL_LEVELS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_SUBJECTS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_USERS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_COURSES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_CLASS_SCHEDULES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_PARENTS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_PAYMENTS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_TOTAL_PAYMENTS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_BRANCHES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_ROLES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_CLASS_ATTENDANCES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_STUDENT_REMARKS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_PAYABLES]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_GRADE_EVALUATIONS]: COLLECTION_INITIAL_STATE,
    [collections.COLLECTION_NOTIFICATIONS]: COLLECTION_INITIAL_STATE,
};

export const rootv2 = (state = ROOT_INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_REQUEST:
        case types.FETCH_SUCCESS:
        case types.FETCH_FAILED:
        case types.ADD_REQUEST:
        case types.ADD_SUCCESS:
        case types.ADD_FAILED:
        case types.ADD_ITEM:
        case types.EDIT_REQUEST:
        case types.EDIT_SUCCESS:
        case types.EDIT_FAILED:
        case types.EDIT_ITEM:
        case types.DELETE_REQUEST:
        case types.DELETE_SUCCESS:
        case types.DELETE_FAILED:
        case types.DELETE_ITEM:
        case types.SUBSCRIBE_REQUEST:
        case types.SUBSCRIBE_SUCCESS:
        case types.SUBSCRIBE_FAILED:
        case types.UNSUBSCRIBE:
        case types.SET_ITEM:
        case types.SET_ITEMS:
        case types.RESET_STATE: {
            return {
                ...state,
                [action.collection]: collection(state[action.collection], action),
            };
        }
        case types.DELETE_COLLECTION: {
            const newState = _.cloneDeep(state);
            delete newState[action.collection];
            return newState;
        }
        default:
            return state;
    }
};

export default rootv2;
