import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import './App.scss';
import ProtectedRoute from 'layouts/ProtectedRoute';
import Swal from 'sweetalert2';
import useNavigatorOnline from 'use-navigator-online';

import dashboardRoutes, { subRoutes, duplicatedRoutes } from 'routes';
import Loader from 'components/Loader';

import ErrorBoundry from './utils/ErrorBoundry';

const Login = lazy(() => import('./views/Login'));
const Unauthorized = lazy(() => import('./views/Unauthorized'));
const PageNotFound = lazy(() => import('./views/PageNotFound'));
const OnlineEnrollmentForm = lazy(() => import('./views/OnlineEnrollmentForm'));
const AdminLayout = lazy(() => import('./layouts/Admin'));

const hist = createBrowserHistory();

function App(props) {
  const { isAuthenticated, isLoading, loggedInUser } = props;
  const { isOffline } = useNavigatorOnline();
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
  });
  if (isOffline) {
    Toast.fire({
      icon: 'error',
      title: 'No internet connection. Offline',
    });
  } else {
    Toast.close();
  }

  return (
    <Router history={hist}>
      <ErrorBoundry>
        <Suspense fallback={<Loader size={4} />}>
          <Switch>
            <Route
              exact
              path="/online-enrollment-application/:id"
              component={OnlineEnrollmentForm}
            />
            <Route exact path="/login" component={Login} />
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              loggedInUser={loggedInUser}
              exact
              path={[
                [''],
                ...Object.values(dashboardRoutes).map(r => r),
                ...subRoutes,
                ...duplicatedRoutes,
              ].map(v => v.path)}
              component={AdminLayout}
            />
            <Route exact path={['/401']} component={Unauthorized} />
            <Route exact path={['/404', '*']} component={PageNotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundry>
    </Router>
  );
}

function mapStateToProps(state) {
  const { root } = state || {};
  const { auth, users } = root || {};
  const { isAuthenticated, isLoading } = auth || {};
  const { loggedInUser } = users || {};

  return {
    isAuthenticated,
    isLoading,
    loggedInUser: loggedInUser,
  };
}

export default connect(mapStateToProps)(App);
