import * as types from '.';

export function setLoggedInUser(data) {
  return {
    type: types.SET_LOGGED_IN_USER,
    payload: data,
  };
}

export function clearLoggedInUser() {
  return {
    type: types.CLEAR_LOGGED_IN_USER,
  };
}
