import MenuItem from "@material-ui/core/MenuItem";

import React from "react";
import moment from "moment";

let apiURL = process.env.REACT_APP_API_URL;

export const EMULATOR_HOST = "127.0.0.1";
export const HTTP_EMULATOR_HOST = `http://${EMULATOR_HOST}`;
export const FIRESTORE_EMULATOR_PORT = 8080;
export const AUTHENTICATION_EMULATOR_PORT = 9099;
export const FUNCTIONS_EMULATOR_PORT = 5001;
export const STORAGE_EMULATOR_PORT = 9199;

if (
    process.env.REACT_APP_FIREBASE_DEBUG_MODE === "true" &&
    process.env.NODE_ENV === "development" &&
    window.location.hostname === "localhost"
) {
    apiURL = `${HTTP_EMULATOR_HOST}:${FUNCTIONS_EMULATOR_PORT}/school-mngmt-platform/asia-northeast1/webApi`;
}

export const API_URL = apiURL;
export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

export const DEFAULT_SCHOOL_YEAR_ID = process.env.REACT_APP_DEFAULT_SCHOOLYEAR_ID;
export const DEFAULT_SCHOOL_ID = process.env.REACT_APP_DEFAULT_SCHOOL_ID;

export const KEY_NOT_FOUND = "KEY_NOT_FOUND";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const DELETED = "DELETED";
export const FIRST_GRADING = "FIRST_GRADING";
export const SECOND_GRADING = "SECOND_GRADING";
export const THIRD_GRADING = "THIRD_GRADING";
export const FOURTH_GRADING = "FOURTH_GRADING";
export const GRADING_PERIOD_NAME = {
    [FIRST_GRADING]: "1st Periodical",
    [SECOND_GRADING]: "2nd Periodical",
    [THIRD_GRADING]: "3rd Periodical",
    [FOURTH_GRADING]: "4th Periodical",
};

// adding these as constants since we have not implemented yet
// a way for the users to select a school and school year
// that is used as a global filter.
export const DEFAULT_SCHOOL = {
    address: "Dakit, Bogo City, Cebu",
    email: "sanroque@gmail.com",
    name: "San Roque College de Cebu",
    id: DEFAULT_SCHOOL_ID,
};
export const DEFAULT_SCHOOL_YEAR = {
    end: new Date("2021", "06", "1"),
    name: "School Year 1",
    start: new Date("2020", "06", "1"),
    id: DEFAULT_SCHOOL_YEAR_ID,
};

export const ADDED = "added";
export const MODIFIED = "modified";

export const MALE = "MALE";
export const FEMALE = "FEMALE";

export const GENDER_SELECTION = {
    [FEMALE]: "Female",
    [MALE]: "Male",
};

export const GENDER_SELECTION_OPTIONS = Object.keys(GENDER_SELECTION).map(key => (
    <MenuItem key={key} value={key}>
        {GENDER_SELECTION[key]}
    </MenuItem>
));

export const ID_LAST_2_DIGITS = {
    [MALE]: "-1",
    [FEMALE]: "-2",
};

export const GET_GENDER_FROM_ID = {
    "1": "MALE",
    "2": "FEMALE",
};

export const FILIPINO = "FILIPINO";
export const FOREIGNER = "FOREIGNER";

export const CITIZENSHIP_SELECTION = {
    [FILIPINO]: "Filipino",
    [FOREIGNER]: "Foreigner",
};

export const CITIZENSHIP_SELECTION_OPTIONS = Object.keys(CITIZENSHIP_SELECTION).map(key => (
    <MenuItem key={key} value={key}>
        {CITIZENSHIP_SELECTION[key]}
    </MenuItem>
));

export const FATHER = "FATHER";
export const MOTHER = "MOTHER";
export const GUARDIAN = "GUARDIAN";

export const PARENT_TYPE_OPTIONS = {
    [FATHER]: "Father",
    [MOTHER]: "Mother",
    [GUARDIAN]: "Guardian",
};

export const PARENT_TYPE_SELECT_OPTIONS = Object.keys(PARENT_TYPE_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {PARENT_TYPE_OPTIONS[key]}
    </MenuItem>
));

export const PRE_SCHOOL = "PRE_SCHOOL";
export const GRADE_SCHOOL = "GRADE_SCHOOL";
export const JUNIOR_HIGH_SCHOOL = "JUNIOR_HIGH_SCHOOL";
export const SENIOR_HIGH_SCHOOL = "SENIOR_HIGH_SCHOOL";
export const COLLEGE = "COLLEGE";

export const EDUCATIONAL_STAGE_OPTIONS = {
    [PRE_SCHOOL]: "Pre School",
    [GRADE_SCHOOL]: "Grade School",
    [JUNIOR_HIGH_SCHOOL]: "Junior High",
    [SENIOR_HIGH_SCHOOL]: "Senior High",
    [COLLEGE]: "College",
};

export const EDUCATIONAL_STAGE_SELECT_OPTIONS = Object.keys(EDUCATIONAL_STAGE_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {EDUCATIONAL_STAGE_OPTIONS[key]}
    </MenuItem>
));

export const STEM = "STEM";
export const ABM = "ABM";
export const HUMMS = "HUMMS";
export const GAS = "GAS";

export const ACADEMIC_TRACK_OPTIONS = {
    [STEM]: "STEM (Science, Technology, Engineering and Mathematics)",
    [ABM]: "ABM (Accountancy, Business and Management)",
    [HUMMS]: "HUMMS (Humanities and Social Sciences)",
    [GAS]: "GAS (General Academic Track)",
};

export const ACADEMIC_TRACK_SELECT_OPTIONS = Object.keys(ACADEMIC_TRACK_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {ACADEMIC_TRACK_OPTIONS[key]}
    </MenuItem>
));

export const BA = "BA";
export const BS = "BS";

export const DEGREE_OPTIONS = {
    [BA]: "BA",
    [BS]: "BS",
};

export const DEGREE_SELECT_OPTIONS = Object.keys(DEGREE_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {DEGREE_OPTIONS[key]}
    </MenuItem>
));

export const DEGREE_OPTIONS_VERBOSE = {
    [BA]: "Bachelor of Arts",
    [BS]: "Bachelor of Science",
};

export const DEGREE_SELECT_OPTIONS_VERBOSE = Object.keys(DEGREE_OPTIONS_VERBOSE).map(key => (
    <MenuItem key={key} value={key}>
        {DEGREE_OPTIONS_VERBOSE[key]}
    </MenuItem>
));

export const OLD_STUDENT = "OLD_STUDENT";
export const NEW_STUDENT = "NEW_STUDENT";
export const RETURNEE = "RETURNEE";

export const ENROLLEE_TYPE_OPTIONS = {
    [NEW_STUDENT]: "New Student",
    [OLD_STUDENT]: "Old Student",
    [RETURNEE]: "Returnee",
};

export const ENROLLEE_TYPE_SELECT_OPTIONS = Object.keys(ENROLLEE_TYPE_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {ENROLLEE_TYPE_OPTIONS[key]}
    </MenuItem>
));

export const GRADING_PERIOD_SELECT_OPTIONS = Object.keys(GRADING_PERIOD_NAME).map(key => (
    <MenuItem key={key} value={key}>
        {GRADING_PERIOD_NAME[key]}
    </MenuItem>
));

export const MARRIED = "MARRIED";
export const SINGLE = "SINGLE";
export const SEPARATED = "SEPARATED";
export const WIDOWED = "WIDOWED";

export const MARITAL_STATUS_OPTIONS = {
    [MARRIED]: "Married",
    [SEPARATED]: "Separated",
    [SINGLE]: "Single",
    [WIDOWED]: "Widowed",
};

export const MARITAL_STATUS_SELECT_OPTIONS = Object.keys(MARITAL_STATUS_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {MARITAL_STATUS_OPTIONS[key]}
    </MenuItem>
));

export const TEST_SCHOOL_ID = "0NR0X316qkhPbi5Z5ctJ";

export const UNDEFINED = "undefined";

export const ROMAN_CATHOLIC = "ROMAN_CATHOLIC";
export const PROTESTANT = "PROTESTANT";
export const ORTHODOX = "ORTHODOX";
export const IGLESIA_NI_CRISTO = "IGLESIA_NI_CRISTO";
export const SEVENTH_DAY_ADVENTIST = "SEVENTH_DAY_ADVENTIST";
export const EVANGELICAL = "EVANGELICAL";
export const CHRISTIAN = "CHRISTIAN";
export const ISLAM = "ISLAM";
export const BUDDHISM = "BUDDHISM";
export const HINDUISM = "HINDUISM";
export const OTHERS = "OTHERS";

export const RELIGION_OPTIONS = {
    [ROMAN_CATHOLIC]: "Roman Catholic",
    [PROTESTANT]: "Protestant",
    [ORTHODOX]: "Orthodox",
    [IGLESIA_NI_CRISTO]: "Iglesia Ni Cristo",
    [SEVENTH_DAY_ADVENTIST]: "Seventh-day Adventist",
    [EVANGELICAL]: "Evangelical",
    [CHRISTIAN]: "Christian",
    [ISLAM]: "Islam",
    [BUDDHISM]: "Buddhism",
    [HINDUISM]: "Hinduism",
    [OTHERS]: "Others",
};

export const RELIGION_SELECT_OPTIONS = Object.keys(RELIGION_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {RELIGION_OPTIONS[key]}
    </MenuItem>
));

export const BOGO = "BOGO";
export const CORDOVA = "CORDOVA";
export const LILO_AN = "LILO_AN";

export const SRCDC_BRANCH_OPTIONS = {
    [BOGO]: "Bogo",
    [CORDOVA]: "Cordova",
    [LILO_AN]: "Lilo-an",
};

export const SRCDC_BRANCH_SELECT_OPTIONS = Object.keys(SRCDC_BRANCH_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {SRCDC_BRANCH_OPTIONS[key]}
    </MenuItem>
));

export const ID_BRANCH_SUFFIX = {
    Bogo: "B",
    Cordova: "C",
    "Lilo-an": "",
};

export const NUMBER_REGEX = /^[0-9]*$/;

export const STAFF = "STAFF";
export const ADMIN = "ADMIN";
export const PRINCIPAL = "PRINCIPAL";
export const TEACHER = "TEACHER";
export const PARENT = "PARENT";
export const ACCOUNTING = "ACCOUNTING";
export const SUPER_ADMIN = "SUPER_ADMIN";

export const USER_ROLE_OPTIONS = {
    [ACCOUNTING]: "Accounting",
    [PARENT]: "Parent",
    [PRINCIPAL]: "Principal",
    [ADMIN]: "School Administrator",
    [STAFF]: "Staff",
    [TEACHER]: "Teacher",
    [SUPER_ADMIN]: "Technify Administrator",
};

export const USER_ROLE_SELECT_OPTIONS = Object.keys(USER_ROLE_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {USER_ROLE_OPTIONS[key]}
    </MenuItem>
));

export const EXPORT_SUBJECT_QUARTERLY = "SUBJECT_QUARTERLY";
export const EXPORT_FINAL_QUARTERLY = "FINAL-QUARTERLY";
export const EXPORT_GENERAL_AVERAGE = "GENERAL-AVERAGE";
export const EXPORT_FINAL_GRADES = "FINAL-GRADES";

export const ONLINE_LEARNING = "ONLINE_LEARNING";
export const OFFLINE_LEARNING = "OFFLINE_LEARNING";
export const BLENDED_LEARNING = "BLENDED_LEARNING";

export const LEARNING_MODE_OPTIONS = {
    [ONLINE_LEARNING]: "Online Learning",
    [OFFLINE_LEARNING]: "Offline Learning",
};

export const LEARNING_MODE_SELECT_OPTIONS = Object.keys(LEARNING_MODE_OPTIONS).map(key => (
    <MenuItem key={key} value={key}>
        {LEARNING_MODE_OPTIONS[key]}
    </MenuItem>
));

export const MAPEH_SUBJECT_KEYWORDS = [
    "music",
    "arts",
    "pe",
    "p.e",
    "health",
    "art",
    "physical education",
];

export const QUARTERS = {
    q1: "Q1",
    q2: "Q2",
    q3: "Q3",
    q4: "Q4",
};

export const QUARTER_LIST = ["Q1", "Q2", "Q3", "Q4"];

export const READABLE_QUARTERS = {
    q1: "1st Quarter",
    q2: "2nd Quarter",
    q3: "3rd Quarter",
    q4: "4rt Quarter",
};

export const FILE_TYPES = {
    pdf: "PDF",
    csv: "CSV",
};

export const CSV = "csv";

export const TRANSMUTATION_TABLE = [
    {
        highGrade: 99.99,
        lowGrade: 98.4,
        tGrade: 99,
    },
    {
        highGrade: 98.39,
        lowGrade: 96.8,
        tGrade: 98,
    },
    {
        highGrade: 96.79,
        lowGrade: 95.2,
        tGrade: 97,
    },
    {
        highGrade: 95.19,
        lowGrade: 93.6,
        tGrade: 96,
    },
    {
        highGrade: 93.59,
        lowGrade: 92.0,
        tGrade: 95,
    },
    {
        highGrade: 91.99,
        lowGrade: 90.4,
        tGrade: 94,
    },
    {
        highGrade: 90.39,
        lowGrade: 88.8,
        tGrade: 93,
    },
    {
        highGrade: 88.79,
        lowGrade: 87.2,
        tGrade: 92,
    },
    {
        highGrade: 87.19,
        lowGrade: 85.6,
        tGrade: 91,
    },
    {
        highGrade: 85.59,
        lowGrade: 84.0,
        tGrade: 90,
    },
    {
        highGrade: 83.99,
        lowGrade: 82.4,
        tGrade: 89,
    },
    {
        highGrade: 82.39,
        lowGrade: 80.8,
        tGrade: 88,
    },
    {
        highGrade: 80.79,
        lowGrade: 79.2,
        tGrade: 87,
    },
    {
        highGrade: 79.19,
        lowGrade: 77.6,
        tGrade: 86,
    },
    {
        highGrade: 77.59,
        lowGrade: 76.0,
        tGrade: 85,
    },
    {
        highGrade: 75.99,
        lowGrade: 74.4,
        tGrade: 84,
    },
    {
        highGrade: 74.39,
        lowGrade: 72.8,
        tGrade: 83,
    },
    {
        highGrade: 72.79,
        lowGrade: 71.2,
        tGrade: 82,
    },
    {
        highGrade: 71.19,
        lowGrade: 69.6,
        tGrade: 81,
    },
    {
        highGrade: 69.59,
        lowGrade: 68.0,
        tGrade: 80,
    },
    {
        highGrade: 67.99,
        lowGrade: 66.4,
        tGrade: 79,
    },
    {
        highGrade: 66.39,
        lowGrade: 64.8,
        tGrade: 78,
    },
    {
        highGrade: 64.79,
        lowGrade: 63.2,
        tGrade: 77,
    },
    {
        highGrade: 63.19,
        lowGrade: 61.6,
        tGrade: 76,
    },
    {
        highGrade: 61.59,
        lowGrade: 60.0,
        tGrade: 75,
    },
    {
        highGrade: 59.99,
        lowGrade: 56.0,
        tGrade: 74,
    },
    {
        highGrade: 55.99,
        lowGrade: 52.0,
        tGrade: 73,
    },
    {
        highGrade: 51.99,
        lowGrade: 48.0,
        tGrade: 72,
    },
    {
        highGrade: 47.99,
        lowGrade: 44.0,
        tGrade: 71,
    },
    {
        highGrade: 43.99,
        lowGrade: 40.0,
        tGrade: 70,
    },
    {
        highGrade: 39.99,
        lowGrade: 36.0,
        tGrade: 69,
    },
    {
        highGrade: 35.99,
        lowGrade: 32.0,
        tGrade: 68,
    },
    {
        highGrade: 31.99,
        lowGrade: 28.0,
        tGrade: 67,
    },
    {
        highGrade: 27.99,
        lowGrade: 24.0,
        tGrade: 66,
    },
    {
        highGrade: 23.99,
        lowGrade: 20.0,
        tGrade: 65,
    },
    {
        highGrade: 19.99,
        lowGrade: 16.0,
        tGrade: 64,
    },
    {
        highGrade: 15.99,
        lowGrade: 12.0,
        tGrade: 63,
    },
    {
        highGrade: 11.99,
        lowGrade: 8.0,
        tGrade: 62,
    },
    {
        highGrade: 7.99,
        lowGrade: 4.0,
        tGrade: 61,
    },
    {
        highGrade: 3.99,
        lowGrade: 0,
        tGrade: 60,
    },
];

export const MONTHS = {
    JAN: "January",
    FEB: "February",
    MAR: "March",
    APR: "April",
    MAY: "May",
    JUNE: "June",
    JULY: "July",
    AUG: "August",
    SEPT: "September",
    OCT: "October",
    NOV: "November",
    DEC: "December",
};

export const OBSERVED_VALUES = [
    {
        coreValue: "Maka-Diyos",
        behaviorStatements:
            "Expresses one's spiritual beliefs while respecting the spiritual beliefs of others",
        name: "makaDiyos",
        statementNum: 1,
    },
    {
        coreValue: "Maka-Diyos",
        behaviorStatements: "Shows adherence to ethical principles by upholding truth",
        name: "makaDiyos",
        statementNum: 2,
    },
    {
        coreValue: "Makatao",
        behaviorStatements: "Is sensitive to individual, social, and cultural differences",
        name: "makatao",
        statementNum: 1,
    },
    {
        coreValue: "Makatao",
        behaviorStatements: "Demonstrates contributions toward solidarity",
        name: "makatao",
        statementNum: 2,
    },
    {
        coreValue: "Makakalikasan",
        behaviorStatements:
            "Cares for the environment and utilizes resources wisely judiciously and economically",
        name: "makakalikasan",
        statementNum: 1,
    },
    {
        coreValue: "Makabansa",
        behaviorStatements:
            "Demonstrates pride in being a Filipino; exercises the rights and responsibilities of a Filipino citizen",
        name: "makabansa",
        statementNum: 1,
    },
    {
        coreValue: "Makabansa",
        behaviorStatements:
            "Demonstrates appropriate behavior in carrying out activities in the school, community and country",
        name: "makabansa",
        statementNum: 2,
    },
];

export const OBSERVED_VALUES_MARKINGS = {
    AO: "Always Observed",
    SO: "Sometimes Observed",
    RO: "Rarely Observed",
    NO: "Not Observed",
};

export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const MAPEH = "MAPEH";

export const MAPEH_SUBJECT = "Mapeh";

export const SUBJECT_ORDER = [
    "MOTHERTONGUE",
    "FILIPINO",
    "ENGLISH",
    "LANGUAGE", // ENGLISH
    "READINGANDLITERACY", // ENGLISH
    "MATHEMATICS",
    "MATH",
    "SCIENCE",
    "CIVICSANDCULTURE",
    "ARALINGPANLIPUNAN",
    "MAKABANSA", // ARALINGPANLIPUNAN
    "TLE",
    "TLE(ICT)",
    "TECHNOLOGYLIVELIHOODEDUCATION",
    "TECHNOLOGYANDLIVELIHOODEDUCATION",
    "HELE",
    "MAPEH",
    "MUSIC",
    "ARTS",
    "PE",
    "P.E",
    "HEALTH",
    "MUSIC&ARTS",
    "PHYSICALEDUCATION&HEALTH",
    "CHRISTIANLIVING",
    "GMRC", // CHRISTIANLIVING
    "COMPUTER",
    "REVITALIZEDVALUESEDUCATION",
    "RVE",
    "R.H.G.P.",
    "RHGP",
    "WRITING",
    "DEPORTMENT",
    "SCOUTING",
];

export const MAPEH_SUBJECTS = [
    "MUSIC",
    "ARTS",
    "PE",
    "HEALTH",
    "MUSIC & ARTS",
    "PHYSICAL EDUCATION & HEALTH",
];

export const MAPEH_SUBJECTS_ORDER = [
    "MAPEH",
    "MUSIC",
    "ARTS",
    "PE",
    "HEALTH",
    "MUSIC & ARTS",
    "PHYSICAL EDUCATION & HEALTH",
];

export const PE_VARIATION_ONE = "PE";

export const PE_VARIATION_TWO = "P.E";

export const EXPORT_TYPE = {
    gradeslip: "Grade Slip",
    reportcard: "Report Card",
};

export const GRADESLIP = "gradeslip";

export const REPORTCARD = "reportcard";

export const PDF = "pdf";

export const EXPORT_REPORT_CARD_GRADE_ROWS = [
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
];

export const EXPORT_REPORT_CARD_CURRICULAR_INVOLVEMENT_ROWS = [
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
];

export const EXPORT_REPORT_CARD_ATTENDANCE_ROWS = [
    "E",
    "F",
    "I",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
];

export const ATTENDANCE_MONTHS = [
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
];

export const EXPORT_REPORT_CARD_ATTENDANCE_MONTH_ROWS = [
    { month: "AUG", row: "D" },
    { month: "SEPT", row: "E" },
    { month: "OCT", row: "F" },
    { month: "NOV", row: "G" },
    { month: "DEC", row: "H" },
    { month: "JAN", row: "I" },
    { month: "FEB", row: "J" },
    { month: "MAR", row: "K" },
    { month: "APR", row: "L" },
    { month: "MAY", row: "M" },
];

export const PASSED = "Passed";

export const FAIL = "Fail";

export const EXPORT_REPORT_CARD_OBSERVED_VALUES_COLUMN = { Q1: "K", Q2: "L", Q3: "M", Q4: "N" };

export const INITIAL_FORM_VALUES = {
    attendance: [],
    observedValues: {
        makaDiyos: ["", ""],
        makatao: ["", ""],
        makakalikasan: ["", ""],
        makabansa: ["", ""],
    },
    coCurricularInvolvement: [],
};

export const MAKA_DIYOS = "makaDiyos";
export const MAKA_TAO = "makatao";
export const MAKAKALIKASAN = "makakalikasan";
export const MAKA_BANSA = "makabansa";

export const REPORT_CARD_INITIAL_FORM_VALUES = {
    attendance: [],
    observedValues: {
        Q1: {
            makaDiyos: ["", ""],
            makatao: ["", ""],
            makakalikasan: ["", ""],
            makabansa: ["", ""],
        },
        Q2: {
            makaDiyos: ["", ""],
            makatao: ["", ""],
            makakalikasan: ["", ""],
            makabansa: ["", ""],
        },
        Q3: {
            makaDiyos: ["", ""],
            makatao: ["", ""],
            makakalikasan: ["", ""],
            makabansa: ["", ""],
        },
        Q4: {
            makaDiyos: ["", ""],
            makatao: ["", ""],
            makakalikasan: ["", ""],
            makabansa: ["", ""],
        },
    },
    coCurricularInvolvement: [],
};

export const INITIAL_EXPORT_FORM_VALUES = {
    admissionToGrade: "",
    admissionDate: "",
    principalId: "",
    teacherId: "",
    teacherName: "",
    principalName: "",
};

export const BACK = "Back";

export const FRONT = "Front";

export const BACK_LOWER_CASE = "back";

export const FRONT_LOWER_CASE = "front";

export const NA = "N/A";

export const COLOR = "color";

export const STRING = "string";

export const MAX_GRADE_SCHOOL_GRADE = 8;

export const MAX_SENIOR_HIGH_SCHOOL_GRADE = 12;

export const CENTER = "center";

export const STUDENT = "Student";

export const FINAL_GRADE = "Final Grade";

export const TRANSPARENT = "transparent";

export const ES = "es";

export const BASE = "base";

export const LEARNERS_NAME = "LEARNER'S NAMES";

export const AVERAGE = "AVERAGE";

export const AUTO = "auto";

export const RIGHT = "right";

export const LEFT = "left";

export const GENERAL_AVERAGE = "GENERAL AVERAGE";

export const SCHOOL_DAYS = "schoolDays";

export const DAYS_PRESENT = "daysPresent";

export const DAYS_TARDY = "daysTardy";

export const REQUIRED = "Required";

export const QA = "QA";

export const EDIT = "Edit";

export const ADD = "Add";

export const SUBMIT = "Submit";

export const NOT_GRADED = "NG";

export const LOWEST_GRADE = 60;

export const FINAL_GRADE_MALE_EXCEL_INDEX = 13;

export const FINAL_GRADE_FEMALE_EXCEL_INDEX = 64;

export const DID_NOT_MET_EXPECTATIONS = "DNME";

export const SCHOOL_YEAR_FORM_INITIAL_VALUES = {
    name: "",
    start: moment().format("YYYY-MM-DD"),
    end: moment()
        .add(1, "years")
        .format("YYYY-MM-DD"),
};

export const ADD_TYPE = "add";

export const USER_STATUS = {
    [ACTIVE]: "Active",
    [INACTIVE]: "Inactive",
    [DELETED]: "Deleted",
};

export { default as dashicon01 } from "../assets/img/icons/dash-icon-01.svg";
export { default as dashicon03 } from "../assets/img/icons/dash-icon-03.svg";
export { default as dashicon04 } from "../assets/img/icons/dash-icon-04.svg";
export { default as awardicon01 } from "../assets/img/icons/award-icon-01.svg";
export { default as awardicon02 } from "../assets/img/icons/award-icon-02.svg";
export { default as awardicon03 } from "../assets/img/icons/award-icon-03.svg";
export { default as awardicon04 } from "../assets/img/icons/award-icon-04.svg";
export { default as teachericon01 } from "../assets/img/icons/teacher-icon-01.svg";
export { default as announcement1 } from "../assets/img/enrollment_announcement.jpg";
export { default as announcement2 } from "../assets/img/announcement1.png";
export { default as announcement3 } from "../assets/img/announcement2.png";
export { default as technifyMale } from "../assets/img/technify_male_default.png";
export { default as technifyFemale } from "../assets/img/technify_female_default.png";
export { default as invoicesicon1 } from "../assets/img/icons/invoices-icon1.svg";
export { default as invoicesicon2 } from "../assets/img/icons/invoices-icon2.svg";
export { default as invoicesicon3 } from "../assets/img/icons/invoices-icon3.svg";
export { default as invoicesicon4 } from "../assets/img/icons/invoices-icon4.svg";
export { default as triangleDangerIcon } from "../assets/img/icons/triangle-danger-icon.png";

export const EDUCATIONAL_STAGE_PRIMARY_COLOR_CODES = {
    [PRE_SCHOOL]: "#FCC659",
    [GRADE_SCHOOL]: "#047AF5",
    [JUNIOR_HIGH_SCHOOL]: "#2ECC71",
    [SENIOR_HIGH_SCHOOL]: "#FF1818",
    [COLLEGE]: "#9B59B6",
};

export const EDUCATIONAL_STAGE_LIGHT_COLOR_CODES = {
    [PRE_SCHOOL]: "#FFECC6",
    [GRADE_SCHOOL]: "#B9DBFF",
    [JUNIOR_HIGH_SCHOOL]: "#BEFFDA",
    [SENIOR_HIGH_SCHOOL]: "#FFD4D4",
    [COLLEGE]: "#9B59B6",
};

export const GRADUATING_LEVEL = ["grade6", "grade12"];

export const REVERSE_QUARTER_LIST = ["Q4", "Q3", "Q2", "Q1"];

export const ATTENDANCE_STATUS_LIST = ["PRESENT", "LATE", "CUTTING", "SICK", "EMERGENCY", "OTHER"];

export const ATTENDANCE_STATUS_OPTIONS = {
    PRESENT: "Present",
    LATE: "Late",
    CUTTING: "Cutting Classes",
    SICK: "Sick",
    EMERGENCY: "Emergency",
    OTHER: "Other",
};

export const ATTENDANCE_STATUS_COLOR_CODES = {
    PRESENT: "#2ECC71",
    LATE: "#047AF5",
    CUTTING: "#FF1818",
    SICK: "#FF1818",
    EMERGENCY: "#FF1818",
    OTHER: "#FF1818",
};

export const DEFAULT_CURRICULUM = "DEFAULT";
export const MATATAG_CURRICULUM = "MATATAG";

export const CURRICULUM_OPTIONS = {
    [DEFAULT_CURRICULUM]: "Default Curriculum",
    [MATATAG_CURRICULUM]: "Matatag Curriculum",
};

export const CURRICULUM_PILOT_OPTIONS = {
    [DEFAULT_CURRICULUM]: {
        type: "ALL",
        level: [],
    },
    [MATATAG_CURRICULUM]: {
        type: "SPECIFIC",
        level: ["grade 4", "grade 7"],
    },
};

export const CURRICULUM_SUB_SUBJECT_OPTIONS = {
    [DEFAULT_CURRICULUM]: [
        {
            name: "music",
            title: "Music",
        },
        {
            name: "arts",
            title: "Arts",
        },
        {
            name: "pe",
            title: "P.E.",
        },
        {
            name: "health",
            title: "Health",
        },
    ],
    [MATATAG_CURRICULUM]: [
        {
            name: "musicAndArts",
            title: "Music & Arts",
        },
        {
            name: "peAndHealth",
            title: "Physical Education & Health",
        },
    ],
};
