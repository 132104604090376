import * as types from './';

export function addStudent(student) {
  return {
    type: types.ADDING_STUDENT_SUCCESS,
    payload: student,
  };
}

export function addStudentRequest() {
  return {
    type: types.ADDING_STUDENT_REQUEST,
  };
}

export function addStudentFailure() {
  return {
    type: types.ADDING_STUDENT_FAILURE,
  };
}

export function clearStudents() {
  return {
    type: types.CLEAR_STUDENTS,
  };
}

export function doneFetchingStudents() {
  return {
    type: types.DONE_FETCHING_STUDENTS,
  };
}

export function fetchingStudents() {
  return {
    type: types.FETCHING_STUDENTS,
  };
}

export function addingStudentItem(data) {
  return {
    type: types.ADDING_STUDENT_ITEM,
    payload: data,
  };
}

export function removingStudentItem(data) {
  return {
    type: types.REMOVING_STUDENT_ITEM,
    payload: data,
  };
}

export function fetchingStudentsFailed() {
  return {
    type: types.FETCHING_STUDENTS_FAILED,
  };
}

export function setStudentItem(data) {
  return {
    type: types.SET_STUDENT_ITEM,
    payload: data,
  };
}

export function fetchStudentCount(data, unsubscribe) {
  return {
    type: types.FETCH_STUDENT_COUNT,
    payload: { data, unsubscribe: unsubscribe },
  };
}

export function fetchingStudentItem() {
  return {
    type: types.FETCHING_STUDENT_ITEM,
  };
}

export function fetchStudentItemSuccess(data) {
  return {
    type: types.FETCH_STUDENT_ITEM_SUCCESS,
    payload: data,
  };
}

export function editStudentItemRequest() {
  return {
    type: types.EDIT_STUDENT_ITEM_REQUEST,
  };
}

export function editStudentItemSuccess() {
  return {
    type: types.EDIT_STUDENT_ITEM_SUCCESS,
  };
}

export function editStudentItemFailed() {
  return {
    type: types.EDIT_STUDENT_ITEM_FAILED,
  };
}

export function deleteStudentItemRequest() {
  return {
    type: types.DELETE_STUDENT_ITEM_REQUEST,
  };
}

export function deleteStudentItemSuccess() {
  return {
    type: types.DELETE_STUDENT_ITEM_SUCCESS,
  };
}

export function deleteStudentItemFailed() {
  return {
    type: types.DELETE_STUDENT_ITEM_FAILED,
  };
}

export function unsubscribeStudentCount() {
  return {
    type: types.UNSUBSCRIBE,
  };
}
