import * as types from '.';

export function clearFilters() {
  return {
    type: types.CLEAR_FILTERS,
  };
}

export function setAppSchool(data) {
  return {
    type: types.SET_APP_SCHOOL,
    payload: data,
  };
}

export function setAppSchoolRequest() {
  return {
    type: types.SET_APP_SCHOOL_REQUEST,
  };
}

export function setAppSchoolYear(data) {
  return {
    type: types.SET_APP_SCHOOL_YEAR,
    payload: data,
  };
}

export function setAppSchoolYearRequest() {
  return {
    type: types.SET_APP_SCHOOL_YEAR_REQUEST,
  };
}

export function setAppBranch(id) {
  return {
    type: types.SET_APP_BRANCH,
    payload: id,
  };
}
