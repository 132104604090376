import * as types from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingFailed: false,

  isSubmitting: false,
  isSubmitFailed: false,

  items: {},
  ids: [],

  item: {},
  isFetchingItem: false,
  isEditing: false,
  unsubscribe: null,
};

export const students = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const { items, ids, item, id } = payload || {};
  switch (type) {
    case types.DELETE_STUDENT_ITEM_REQUEST:
      return state;
    case types.DELETE_STUDENT_ITEM_SUCCESS:
      return state;
    case types.DELETE_STUDENT_ITEM_FAILED:
      return state;
    case types.EDIT_STUDENT_ITEM_REQUEST:
      return {
        ...state,
        isEditing: true,
        isEditingFailed: false,
        isEditingSuccess: false,
      };
    case types.EDIT_STUDENT_ITEM_SUCCESS:
      return {
        ...state,
        isEditing: false,
        isEditingFailed: false,
        isEditingSuccess: true,
      };
    case types.EDIT_STUDENT_ITEM_FAILED:
      return {
        ...state,
        isEditing: false,
        isEditingFailed: true,
        isEditingSuccess: false,
      };
    case types.FETCH_STUDENT_COUNT:
      return {
        ...state,
        count: payload.data,
        unsubscribe: payload.unsubscribe,
      };
    case types.FETCHING_STUDENT_ITEM:
      return {
        ...state,
        isFetchingItem: true,
      };
    case types.FETCH_STUDENT_ITEM_SUCCESS:
      return {
        ...state,
        item: payload,
        isFetchingItem: false,
      };

    case types.SET_STUDENT_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          ...item,
        },
      };

    case types.FETCHING_STUDENTS:
      return {
        ...state,
        isFetching: true,
        isFetchingFailed: false,
      };
    case types.FETCHING_STUDENTS_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetchingFailed: true,
        items: {},
        ids: [],
      };
    case types.ADDING_STUDENT_ITEM:
      return {
        ...state,
        ...{
          items: {
            ...state.items,
            ...items,
          },
          ids: Array.from(new Set([...state.ids, ...ids])),
          isSubmitting: false,
          isSubmitFailed: false,
          isFetching: false,
        },
      };
    case types.REMOVING_STUDENT_ITEM:
      let newState = _.cloneDeep(state);

      delete newState.items[id];
      const index = newState.ids.indexOf(id);

      return {
        ...state,
        items: newState.items,
        ids: Array.from(
          new Set([...newState.ids.slice(0, index), ...newState.ids.slice(index + 1)])
        ),
      };

    case types.ADDING_STUDENT_REQUEST:
      return {
        ...state,
        ...{
          isSubmitting: true,
          isSubmitFailed: false,
        },
      };

    case types.ADDING_STUDENT_FAILURE:
      return {
        ...state,
        ...{
          isSubmitting: false,
          isSubmitFailed: true,
        },
      };

    case types.CLEAR_STUDENTS:
      return {
        ...state,
        items: {},
        item: {},
        ids: [],
        isFetchingFailed: false,
        isSubmitting: false,
        isSubmitFailed: false,
        isFetchingItem: false,
        isEditing: false,
      };
    case types.DONE_FETCHING_STUDENTS:
      return {
        ...state,
        isFetching: false,
      };
    case types.UNSUBSCRIBE:
      if (state.unsubscribe) {
        state.unsubscribe();
      }
      return {
        ...state,
        unsubscribe: null,
      };
    default:
      return state;
  }
};
