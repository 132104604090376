import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import { withStyles } from '@material-ui/core/styles';

import { Box, Button, Typography } from '@material-ui/core';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    console.error(`[Error] ${error.name}: ${error.message}`);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <ErrorBoundaryContainer>
            <ErrorTitle>Oh snap! An error occured :(</ErrorTitle>
            <Button
              color="primary"
              onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
            >
              Send a report.
            </Button>
          </ErrorBoundaryContainer>
        </Container>
      );
    }
    return this.props.children;
  }
}

const Container = withStyles({
  root: {
    textAlign: 'center',
  },
})(Box);

const ErrorBoundaryContainer = withStyles({
  root: {
    marginTop: '15%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 'fit-content',
    margin: 'auto',
  },
})(Box);

const ErrorTitle = withStyles({
  root: {
    fontWeight: 700,
    fontSize: 80,
  },
})(Typography);
