import React from "react";

const ThreeBarIcon = props => {
    return (
        <svg
            {...props}
            className="MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            style={{
                fill: "transparent",
                stroke: "currentColor",
            }}
        >
            <path
                d="M18 1H2C1.44772 1 1 1.44772 1 2V7C1 7.55228 1.44772 8 2 8H18C18.5523 8 19 7.55228 19 7V2C19 1.44772 18.5523 1 18 1Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 12H2C1.44772 12 1 12.4477 1 13V18C1 18.5523 1.44772 19 2 19H7C7.55228 19 8 18.5523 8 18V13C8 12.4477 7.55228 12 7 12Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 12H13C12.4477 12 12 12.4477 12 13V18C12 18.5523 12.4477 19 13 19H18C18.5523 19 19 18.5523 19 18V13C19 12.4477 18.5523 12 18 12Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ThreeBarIcon;
