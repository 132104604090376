import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Swal from 'sweetalert2';

export default function Loader({ size = 3 }) {
  const arr = new Array(size).fill(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
      });
      Toast.fire({
        icon: 'warning',
        title: "Detected slow internet connection. Try refreshing the page if you're stuck",
      });
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div style={{ margin: 'auto' }}>
      {arr.map((x, i) => (
        <LinearProgress key={i} />
      ))}
    </div>
  );
}
