import * as types from '../actions/types';

const INITIAL_STATE = {
  school: {},
  schoolYear: {},
  branch: '',
  isFetchingSchool: false,
  isFetchingSchoolYear: false,
};

export const filters = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const { item } = payload || {};
  switch (type) {
    case types.SET_APP_SCHOOL_YEAR:
      return {
        ...state,
        schoolYear: {
          ...state.item,
          ...item,
        },
        isFetchingSchoolYear: false,
      };
    case types.SET_APP_SCHOOL_YEAR_REQUEST:
      return {
        ...state,
        isFetchingSchoolYear: true,
      };
    case types.SET_APP_SCHOOL:
      return {
        ...state,
        school: {
          ...state.item,
          ...item,
        },
        isFetchingSchool: false,
      };
    case types.SET_APP_SCHOOL_REQUEST:
      return {
        ...state,
        isFetchingSchool: true,
      };
    case types.SET_APP_BRANCH:
      return {
        ...state,
        branch: item,
      };
    case types.CLEAR_FILTERS:
      return {
        ...state,
        school: {},
        schoolYear: {},
        branch: '',
        isFetchingSchool: true,
        isFetchingSchoolYear: true,
      };
    default:
      return state;
  }
};
