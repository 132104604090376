import * as types from '../actions/types';

const INITIAL_STATE = {
  isLoading: true,
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  user: {},
};

export const auth = (state = INITIAL_STATE, action) => {
  const {type} = action;
  switch (type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
        isLoading: false,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
        isLoading: false,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        isLoading: false,
        user: {}
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true
      };
    case types.VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false
      };
    case types.VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false
      };
      case types.SET_USER_TOKEN:
        return {
          ...state,
          token: action.token,
        };
    case types.ERROR_TOKEN_REQUEST:
      return {
        ...state,
        token: null,
      };

    default:
      return state;
  }
};
