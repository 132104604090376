export const COLLECTION_ACTIVITIES = "activities";
export const COLLECTION_STUDENTS = "students";
export const COLLECTION_STUDENT_SUBJECT_QUARTERLY_GRADES = "studentSubjectQuarterlyGrades";
export const COLLECTION_QUARTERLY_GRADES_SUMMARY = "quarterlyGradesSummary";
export const COLLECTION_SUBJECT_FINAL_GRADES = "subjectFinalGrades";
export const COLLECTION_GENERAL_AVERAGES = "generalAverages";
export const COLLECTION_SECTIONS = "sections";
export const COLLECTION_ENROLLMENTS = "enrollments";
export const COLLECTION_ATTENDANCE = "attendance";
export const COLLECTION_TEACHERS = "teachers";
export const COLLECTION_SCHOOLS = "schools";
export const COLLECTION_SCHOOL_YEARS = "schoolYears";
export const COLLECTION_OLD_SCHOOL_YEARS = "oldSchoolYears";
export const COLLECTION_SCHOOL_LEVELS = "schoolLevels";
export const COLLECTION_SUBJECTS = "subjects";
export const COLLECTION_USERS = "users";
export const COLLECTION_COURSES = "courses";
export const COLLECTION_CLASS_SCHEDULES = "classSchedules";
export const COLLECTION_PARENTS = "parents";
export const COLLECTION_PAYMENTS = "payments";
export const COLLECTION_TOTAL_PAYMENTS = "totalPayments";
export const COLLECTION_BRANCHES = "branches";
export const COLLECTION_EXPORT_VALUES = "exportValues";
export const COLLECTION_ROLES = "roles";
export const COLLECTION_CLASS_ATTENDANCES = "classAttendances";
export const COLLECTION_STUDENT_REMARKS = "studentRemarks";
export const COLLECTION_PAYABLES = "payables";
export const COLLECTION_GRADE_EVALUATIONS = "gradeEvaluations";
export const COLLECTION_NOTIFICATIONS = "notifications";

export const ADDED = "added";
export const MODIFIED = "modified";
export const REMOVED = "removed";
