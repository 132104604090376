import { db } from "utils/store";
import { getBlobFromStorage, blobToB64, isEmptyObject } from "utils/helpers";
import { setLoggedInUser, clearLoggedInUser } from "./types/users";
import {
    setAppSchool,
    setAppSchoolYear,
    setAppSchoolRequest,
    setAppSchoolYearRequest,
    setAppBranch,
} from "./types/filters";
import { fetchStudentCount } from "./students";
import { ACTIVE, TEACHER } from "config/constants";
import { logoutUser } from "./auth";
import { COLLECTION_TEACHERS, COLLECTION_USERS } from "constants/firestore";

export const getLoggedInUser = () => async (dispatch, getState) => {
    const { root } = getState() || {};
    const { auth } = root || {};
    const { user } = auth || {};
    const { uid } = user || {};

    let userData = {};
    if (sessionStorage.getItem("userData") === null) {
        const snapshot = await db
            .collection("users")
            .doc(uid)
            .get();
        userData = { ...snapshot.data(), id: uid };
        sessionStorage.setItem("userData", JSON.stringify(userData));
    } else {
        userData = JSON.parse(sessionStorage.getItem("userData"));
    }

    if (userData.status !== ACTIVE) {
        dispatch(logoutUser());
        return;
    }

    dispatch(setLoggedInUser(userData));
    dispatch(setAppSchool({ item: { id: userData.schoolId } }));
    dispatch(setAppSchoolYear({ item: { id: userData.schoolYearId } }));

    dispatch(fetchStudentCount(userData.schoolId, userData.schoolYearId));

    if (sessionStorage.getItem("branch") === null) {
        sessionStorage.setItem("branch", userData.branch || "");
        dispatch(setAppBranch({ item: userData.branch || "" }));
    } else {
        const branch = sessionStorage.getItem("branch");
        dispatch(setAppBranch({ item: branch }));
    }

    dispatch(setAppSchoolRequest());
    dispatch(setAppSchoolYearRequest());

    if (sessionStorage.getItem("schoolData") === null) {
        db.collection("schools")
            .doc(userData.schoolId)
            .get()
            .then(doc => {
                if (doc.data().logo) {
                    getBlobFromStorage(doc.data().logo).then(blob =>
                        blobToB64(blob, b64 => {
                            dispatch(
                                setAppSchool({
                                    item: { ...doc.data(), id: doc.id, logoImage: b64 },
                                })
                            );
                            sessionStorage.setItem(
                                "schoolData",
                                JSON.stringify({ ...doc.data(), id: doc.id, logoImage: b64 })
                            );
                        })
                    );
                } else {
                    sessionStorage.setItem(
                        "schoolData",
                        JSON.stringify({ ...doc.data(), id: doc.id })
                    );
                    dispatch(setAppSchool({ item: { ...doc.data(), id: doc.id } }));
                }
            });
    } else {
        const schoolData = JSON.parse(sessionStorage.getItem("schoolData"));
        dispatch(setAppSchool({ item: { ...schoolData } }));
    }

    if (sessionStorage.getItem("schoolYearData") === null) {
        db.collection("schoolYears")
            .doc(userData.schoolYearId || "None")
            .get()
            .then(doc => {
                sessionStorage.setItem(
                    "schoolYearData",
                    JSON.stringify({ ...doc.data(), id: doc.id })
                );
                dispatch(setAppSchoolYear({ item: { ...doc.data(), id: doc.id } }));
            });
    } else {
        const schoolYearData = JSON.parse(sessionStorage.getItem("schoolYearData"));
        dispatch(setAppSchoolYear({ item: { ...schoolYearData } }));
    }

    if (sessionStorage.getItem("userData") !== null) {
        db.collection(COLLECTION_USERS)
            .doc(uid)
            .get()
            .then(doc => {
                if (doc.data().status !== ACTIVE) {
                    dispatch(logoutUser());
                    return;
                }
            });
    }

    if (sessionStorage.getItem("userData") !== null) {
        const roles = userData.roles;

        if (roles && roles.length === 1) {
            db.collection(COLLECTION_TEACHERS)
                .where("schoolId", "==", userData.schoolId)
                .where("schoolYearId", "==", userData.schoolYearId)
                .where("branch", "==", userData.branch)
                .where("email", "==", userData.email)
                .where("status", "==", ACTIVE)
                .get()
                .then(snapshot => {
                    let item = {};

                    snapshot.docs.forEach(doc => {
                        item = { ...doc.data(), docId: doc.id };
                    });

                    if (!isEmptyObject(item)) {
                        dispatch(setLoggedInUser({ ...userData, teacherInformation: item }));
                    }
                });
        }
    }
};

export const clearUser = () => dispatch => dispatch(clearLoggedInUser());
