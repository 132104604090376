import * as types from '.';

export function requestLogin() {
  return {
    type: types.LOGIN_REQUEST,
  };
}

export function receiveLogin(user) {
  return {
    type: types.LOGIN_SUCCESS,
    user,
  };
}

export function loginError() {
  return {
    type: types.LOGIN_FAILURE,
  };
}

export function requestLogout() {
  return {
    type: types.LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: types.LOGOUT_SUCCESS,
  };
}

export function logoutError() {
  return {
    type: types.LOGOUT_FAILURE,
  };
}

export function verifyRequest() {
  return {
    type: types.VERIFY_REQUEST,
  };
}

export function verifySuccess() {
  return {
    type: types.VERIFY_SUCCESS,
  };
}

export function setUserToken(token) {
  return {
    type: types.SET_USER_TOKEN,
    token,
  };
}

export function errorTokenRequest() {
  return {
    type: types.ERROR_TOKEN_REQUEST,
  };
}
