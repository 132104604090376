import React from "react";

const CalendarIcon = props => {
    return (
        <svg
            // viewBox="0 0 20 22"
            {...props}
            className="MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            style={{
                fill: "transparent",
                stroke: "currentColor",
            }}
        >
            <path
                d="M6 1V5M14 1V5M1 9H19M6 13H6.01M10 13H10.01M14 13H14.01M6 17H6.01M10 17H10.01M14 17H14.01M3 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CalendarIcon;
