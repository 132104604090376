export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILED = 'FETCH_FAILED';
export const ADD_REQUEST = 'ADD_REQUEST';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const ADD_FAILED = 'ADD_FAILED';
export const ADD_ITEM = 'ADD_ITEM';
export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAILED = 'EDIT_FAILED';
export const EDIT_ITEM = 'EDIT_ITEM';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILED = 'DELETE_FAILED';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';
export const RESET_STATE = 'RESET_STATE';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const SET_ITEM = 'SET_ITEM';
export const SET_ITEMS = 'SET_ITEMS';

export const setItems = (collection, payload) => {
  return {
    type: SET_ITEMS,
    collection,
    payload,
  };
};

export const setItem = (collection, payload) => {
  return {
    type: SET_ITEM,
    collection,
    payload,
  };
};

export const unsubscribe = collection => {
  return {
    type: UNSUBSCRIBE,
    collection,
  };
};

export const deleteCollection = collection => {
  return {
    type: DELETE_COLLECTION,
    collection,
  };
};

export const clear = collection => {
  return {
    type: RESET_STATE,
    collection,
  };
};

export const addItem = (collection, payload) => {
  return {
    type: ADD_ITEM,
    collection,
    payload,
  };
};

export const editItem = (collection, payload) => {
  return {
    type: EDIT_ITEM,
    collection,
    payload,
  };
};
export const deleteItem = (collection, payload) => {
  return {
    type: DELETE_ITEM,
    collection,
    payload,
  };
};

export const fetchRequest = collection => {
  return {
    type: FETCH_REQUEST,
    collection,
  };
};

export const fetchSuccess = collection => {
  return {
    type: FETCH_SUCCESS,
    collection,
  };
};

export const fetchFailed = (collection, error) => {
  return {
    type: FETCH_FAILED,
    collection,
    error,
  };
};

export const addRequest = collection => {
  return {
    type: ADD_REQUEST,
    collection,
  };
};

export const addSuccess = collection => {
  return {
    type: ADD_SUCCESS,
    collection,
  };
};

export const addFailed = (collection, error) => {
  return {
    type: ADD_FAILED,
    collection,
    error,
  };
};

export const editRequest = collection => {
  return {
    type: EDIT_REQUEST,
    collection,
  };
};

export const editSuccess = collection => {
  return {
    type: EDIT_SUCCESS,
    collection,
  };
};

export const editFailed = (collection, error) => {
  return {
    type: EDIT_FAILED,
    collection,
    error,
  };
};

export const deleteRequest = collection => {
  return {
    type: DELETE_REQUEST,
    collection,
  };
};

export const deleteSuccess = collection => {
  return {
    type: DELETE_SUCCESS,
    collection,
  };
};

export const deleteFailed = (collection, error) => {
  return {
    type: DELETE_FAILED,
    collection,
    error,
  };
};

export const subscribeRequest = collection => {
  return {
    type: SUBSCRIBE_REQUEST,
    collection,
  };
};

export const subscribeSuccess = (collection, subscription) => {
  return {
    type: SUBSCRIBE_SUCCESS,
    collection,
    subscription,
  };
};

export const subscribeFailed = (collection, error) => {
  return {
    type: SUBSCRIBE_FAILED,
    collection,
    error,
  };
};
