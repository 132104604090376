import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import { authListen } from "actions/auth";
import {
    AUTHENTICATION_EMULATOR_PORT,
    EMULATOR_HOST,
    firebaseConfig,
    FIRESTORE_EMULATOR_PORT,
    FUNCTIONS_EMULATOR_PORT,
    HTTP_EMULATOR_HOST,
    STORAGE_EMULATOR_PORT,
} from "../config/constants";
import appReducer from "../reducers";
const isDevEnv = process.env.NODE_ENV !== "development";

firebase.initializeApp(firebaseConfig);

const firebaseAuth = firebase.auth();
const firebaseDb = firebase.firestore();
const firebaseFunctions = firebase.app().functions("asia-northeast1");
const firebaseStorage = firebase.storage();

if (
    process.env.REACT_APP_FIREBASE_DEBUG_MODE === "true" &&
    !isDevEnv &&
    window.location.hostname === "localhost"
) {
    firebaseAuth.useEmulator(`${HTTP_EMULATOR_HOST}:${AUTHENTICATION_EMULATOR_PORT}`);
    firebaseDb.useEmulator(EMULATOR_HOST, FIRESTORE_EMULATOR_PORT);
    firebaseFunctions.useEmulator(EMULATOR_HOST, FUNCTIONS_EMULATOR_PORT);
    firebaseStorage.useEmulator(EMULATOR_HOST, STORAGE_EMULATOR_PORT);
}

firebase
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(error => {
        if (error.code === "failed-precondition") {
            console.error(`[Error] ${error.name}: ${error.message}`);
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
        } else if (error.code === "unimplemented") {
            console.error(`[Error] ${error.name}: ${error.message}`);
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });

export const fbAuth = firebaseAuth;
export const db = firebaseDb;
export const functions = firebaseFunctions;
export const storageRef = firebaseStorage.ref();

const rootReducer = combineReducers({
    root: appReducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
    stateReconciler: autoMergeLevel2,
};

const middleware = isDevEnv ? [thunkMiddleware] : [thunkMiddleware, logger];

const enhancer = composeWithDevTools(applyMiddleware(...middleware));
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, {}, enhancer);
const persistor = persistStore(store);

store.dispatch(authListen());

export default function configureStore() {
    return { store, persistor };
}
