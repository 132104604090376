import React from "react";

const GraduationCapIcon = props => {
    return (
        <svg
            // viewBox="0 0 21 22"
            {...props}
            className="MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            style={{
                fill: "transparent",
                stroke: "currentColor",
            }}
        >
            <path
                d="M4.0476 12.0832L4.0376 16.7732C4.0376 18.0432 5.0176 19.4032 6.2176 19.8032L9.4076 20.8632C9.9576 21.0432 10.8676 21.0432 11.4276 20.8632L14.6176 19.8032C15.8176 19.4032 16.7976 18.0432 16.7976 16.7732V12.1332M19.8174 14.0031V8.00314M8.4675 1.53312L2.4475 5.46312C0.5175 6.72312 0.5175 9.54312 2.4475 10.8031L8.4675 14.7331C9.5475 15.4431 11.3275 15.4431 12.4075 14.7331L18.3975 10.8031C20.3175 9.54312 20.3175 6.73312 18.3975 5.47312L12.4075 1.54312C11.3275 0.823118 9.5475 0.823118 8.4675 1.53312Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default GraduationCapIcon;
