import * as types from './types/filters';

export const setAppSchool = item => dispatch => {
  dispatch(
    types.setAppSchool({
      item,
    })
  );
};

export const setAppSchoolYear = item => dispatch => {
  dispatch(
    types.setAppSchoolYear({
      item,
    })
  );
};

export const clearFilters = () => dispatch => {
  dispatch(types.clearFilters());
};
