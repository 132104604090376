export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const ERROR_TOKEN_REQUEST = 'ERROR_TOKEN_REQUEST';

export const ADDING_USER_REQUEST = 'ADDING_USER_REQUEST';
export const ADDING_USER_SUCCESS = 'ADDING_USER_SUCCESS';
export const ADDING_USER_FAILURE = 'ADDING_USER_FAILURE';

export const FETCHING_USERS = 'FETCHING_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const ADDING_USER_ITEM = 'ADDING_USER_ITEM';
export const FETCHING_USERS_FAILED = 'FETCHING_USERS_FAILED';
export const REMOVING_USER_ITEM = 'REMOVING_USER_ITEM';

export const SET_USER_ITEM = 'SET_USER_ITEM';
export const FETCHING_USER_ITEM = 'FETCHING_USER_ITEM';
export const FETCH_USER_ITEM_SUCCESS = 'FETCH_USER_ITEM_SUCCESS';

export const EDIT_USER_ITEM_REQUEST = 'EDIT_USER_ITEM_REQUEST';
export const EDIT_USER_ITEM_SUCCESS = 'EDIT_USER_ITEM_SUCCESS';
export const EDIT_USER_ITEM_FAILED = 'EDIT_USER_ITEM_FAILED';

export const DELETE_USER_ITEM_REQUEST = 'DELETE_USER_ITEM_REQUEST';
export const DELETE_USER_ITEM_SUCCESS = 'DELETE_USER_ITEM_SUCCESS';
export const DELETE_USER_ITEM_FAILED = 'DELETE_USER_ITEM_FAILED';

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const CLEAR_LOGGED_IN_USER = 'CLEAR_LOGGED_IN_USER';

// STUDENTS
export const ADDING_STUDENT_REQUEST = 'ADDING_STUDENT_REQUEST';
export const ADDING_STUDENT_SUCCESS = 'ADDING_STUDENT_SUCCESS';
export const ADDING_STUDENT_FAILURE = 'ADDING_STUDENT_FAILURE';

export const FETCHING_STUDENTS = 'FETCHING_STUDENTS';
export const CLEAR_STUDENTS = 'CLEAR_STUDENTS';
export const DONE_FETCHING_STUDENTS = 'DONE_FETCHING_STUDENTS';
export const ADDING_STUDENT_ITEM = 'ADDING_STUDENT_ITEM';
export const FETCHING_STUDENTS_FAILED = 'FETCHING_STUDENTS_FAILED';
export const REMOVING_STUDENT_ITEM = 'REMOVING_STUDENT_ITEM';

export const SET_STUDENT_ITEM = 'SET_STUDENT_ITEM';
export const FETCH_STUDENT_COUNT = 'FETCH_STUDENT_COUNT';
export const FETCHING_STUDENT_ITEM = 'FETCHING_STUDENT_ITEM';
export const FETCH_STUDENT_ITEM_SUCCESS = 'FETCH_STUDENT_ITEM_SUCCESS';

export const EDIT_STUDENT_ITEM_REQUEST = 'EDIT_STUDENT_ITEM_REQUEST';
export const EDIT_STUDENT_ITEM_SUCCESS = 'EDIT_STUDENT_ITEM_SUCCESS';
export const EDIT_STUDENT_ITEM_FAILED = 'EDIT_STUDENT_ITEM_FAILED';

export const DELETE_STUDENT_ITEM_REQUEST = 'DELETE_STUDENT_ITEM_REQUEST';
export const DELETE_STUDENT_ITEM_SUCCESS = 'DELETE_STUDENT_ITEM_SUCCESS';
export const DELETE_STUDENT_ITEM_FAILED = 'DELETE_STUDENT_ITEM_FAILED';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
// END STUDENTS

// GLOBAL FILTERS
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_APP_SCHOOL_REQUEST = 'SET_APP_SCHOOL_REQUEST';
export const SET_APP_SCHOOL_YEAR_REQUEST = 'SET_APP_SCHOOL_YEAR_REQUEST';
export const SET_APP_SCHOOL = 'SET_APP_SCHOOL';
export const SET_APP_SCHOOL_YEAR = 'SET_APP_SCHOOL_YEAR';
export const SET_APP_BRANCH = 'SET_APP_BRANCH';
// END GLOBAL FILTERS
